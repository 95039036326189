import { CenteredLoading, mt } from '@openeducation/oe-it-components';
import { teacherProfileAtom } from '@openeducation/oe-it-jotai-atoms';
import { getUrlParams } from '@openeducation/oe-it-services';
import { CourseType } from '@openeducation/oe-it-shared-types';
import { ClassroomPage } from '@openeducation/oe-it-teacher-portal';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { Linking } from 'react-native';
import { useNavigate } from 'react-router-native';

import { useClassroomErrorMessage } from './use-classroom-error-message';

export const ClassroomScreen = () => {
  const navigate = useNavigate();
  const {
    studentId,
    classroomId,
    teacherId,
    classroomUrl,
    adobeClassroomUrl,
    learningMaterialUrl,
    classType,
  } = getUrlParams();

  const isPrivateClass = classType === CourseType.PRIVATE_CLASS;

  const teacherProfile = useAtomValue(teacherProfileAtom);

  const { message } = useClassroomErrorMessage({ classroomId, classroomUrl, classType });

  useEffect(() => {
    const classUrl = classroomUrl || adobeClassroomUrl;

    if (classUrl) {
      Linking.openURL(decodeURIComponent(classUrl));
    }
    if (!!learningMaterialUrl && !isPrivateClass) {
      Linking.openURL(decodeURIComponent(learningMaterialUrl));
    }

    if (isPrivateClass && studentId && classroomId) {
      if (!teacherId) {
        navigate(`/pending-feedbacks?studentId=${studentId}&classroomId=${classroomId}`);
      } else {
        navigate(
          `/student-pending-feedback?studentId=${studentId}&classroomId=${classroomId}&teacherId=${teacherId}`,
        );
      }
    }
  }, [
    adobeClassroomUrl,
    classroomId,
    classroomUrl,
    isPrivateClass,
    learningMaterialUrl,
    navigate,
    studentId,
    teacherId,
  ]);

  if (!teacherProfile) {
    return <CenteredLoading size={100} style={mt(100)} />;
  }

  return <ClassroomPage message={message} />;
};
