import { ChangePasswordScreen } from '_/screens/change-password-screen/change-password-screen';
import { ClassroomScreen } from '_/screens/classroom-screen/classroom-screen';
import { MessagesScreen } from '_/screens/messages-screen/messages-screen';
import React from 'react';

import { Navigate } from './router';
import { DashboardScreen } from '../screens/dashboard-screen/dashboard-screen';
import { HybridClassesScreen } from '../screens/hybrid-classes-screen/hybrid-classes-screen';
import { PendingFeedbacksScreen } from '../screens/pending-feedbacks-screen/pending-feedbacks-screen';
import { StudentPendingFeedbackScreen } from '../screens/student-pending-feedback-screen/student-pending-feedback-screen';

export const privateRoutes = [
  {
    path: '/',
    private: true,
    component: <Navigate replace to="/dashboard" />,
  },
  {
    path: '/dashboard',
    private: true,
    component: <DashboardScreen />,
  },
  {
    path: '/change-password',
    private: true,
    component: <ChangePasswordScreen />,
  },
  {
    path: '/messages',
    private: true,
    component: <MessagesScreen />,
  },
  {
    path: '/pending-feedbacks',
    private: true,
    component: <PendingFeedbacksScreen />,
  },
  {
    path: '/student-pending-feedback',
    private: true,
    component: <StudentPendingFeedbackScreen />,
  },
  {
    path: '/classroom',
    private: true,
    component: <ClassroomScreen />,
  },
  {
    path: '/hybrid-classes',
    private: true,
    component: <HybridClassesScreen />,
  },
];
