import { OEModalWithinContext } from '@openeducation/oe-it-components';
import { teacherProfileAtom, appVersionAtom } from '@openeducation/oe-it-jotai-atoms';
import { useLoginContext, usePersonProfile, useConfig } from '@openeducation/oe-it-providers';
import { KeepAlive } from '@openeducation/oe-it-teacher-portal';
import { useSetAtom } from 'jotai';
import React, { useEffect } from 'react';

import { version } from './package.json';
import { AppProviders } from './src/providers/app-providers';
import { AppRoutes } from './src/router/app-routing';
import { Router } from './src/router/router';

const App = () => {
  const { config } = useConfig();

  if (!config) {
    return null;
  }

  return (
    <AppProviders env={config.environment}>
      <OEModalWithinContext />
      <AppWithoutProviders />
    </AppProviders>
  );
};

export function AppWithoutProviders() {
  const { personId } = useLoginContext();
  const { personProfile: teacherProfile } = usePersonProfile({ personId });
  const setTeacherProfile = useSetAtom(teacherProfileAtom);
  const setAppVersion = useSetAtom(appVersionAtom);

  useEffect(() => {
    setAppVersion(version);
  }, [setAppVersion]);

  useEffect(() => {
    if (teacherProfile) {
      setTeacherProfile(teacherProfile);
    }
  }, [setTeacherProfile, teacherProfile]);

  return (
    <>
      <KeepAlive />
      <Router>
        <AppRoutes />
      </Router>
    </>
  );
}

export default App;
