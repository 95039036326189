import { CenteredLoading, ErrorMessage, mt } from '@openeducation/oe-it-components';
import { teacherProfileAtom } from '@openeducation/oe-it-jotai-atoms';
import { getUrlParams } from '@openeducation/oe-it-services';
import { HybridClassesPage } from '@openeducation/oe-it-teacher-portal';
import { useAtomValue } from 'jotai';
import * as React from 'react';

export const HybridClassesScreen = () => {
  const teacherProfile = useAtomValue(teacherProfileAtom);
  const params = getUrlParams();

  if (!teacherProfile) {
    return <CenteredLoading size={100} style={mt(100)} />;
  }

  if (!params?.classroomId) {
    return (
      <ErrorMessage message="There is an error with your request: classroomId is a required parameter" />
    );
  }

  return (
    <HybridClassesPage
      classroomId={String(params?.classroomId)}
      facultyId={teacherProfile.sfdcFacultyId}
    />
  );
};
